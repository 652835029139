interface SeoParams {
  alias: string;
  content_title: string;
  meta_title: string;
  meta_description: string;
  meta_keyword: string;
  seo_img: string;
}

const useBaseSeo = ({ content_title, meta_title, meta_description, meta_keyword, alias, seo_img }: SeoParams) => {
  useHead({
    title: content_title,
    meta: [
      // Base
      {
        hid: 'description',
        name: 'description',
        content: meta_description || ''
      },
      {
        name: 'keywords',
        content: meta_keyword || ''
      },

      // FB
      {
        hid: 'og:url',
        property: 'og:url',
        content: alias || ''
      },
      {
        hid: 'og:title',
        property: 'og:title',
        content: meta_title || ''
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: meta_description || ''
      },
      {
        hid: 'og:image',
        property: 'og:image',
        content: seo_img || ''
      },

      // Twitter
      {
        name: 'twitter:card',
        content: 'summary_large_image'
      },
      {
        name: 'twitter:url',
        content: alias || ''
      },
      {
        name: 'twitter:title',
        content: meta_title || ''
      },
      {
        name: 'twitter:description',
        content: meta_description || ''
      },
      {
        name: 'twitter:image',
        content: seo_img || ''
      }
    ],
    link: [
      {
        rel: 'canonical',
        href: alias || ''
      }
    ]
  })
}

export default useBaseSeo
